@import "mixins";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

* {
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
   box-sizing: border-box; 
}

.home-top-container {
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  margin-bottom: 2em;
  background: linear-gradient(to top, black, rgba(0, 0, 0, 0.387));
}

.description-text {
  font-size: .9em;
  font-family: 'Poppins';
  text-align: left;

  @include sm {
    font-size: 0.9em;
  }

}

.ecoLogo {
  width: 18.5em;
  margin-top: 4.5em;
  margin-bottom: 3em;

  @include sm {
    width: 13em;
    margin-bottom: 2em;
    margin-top: 3.2em;
  }

}

.home-top-container video {
  position: absolute;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.65;

  @include sm {
    display: none;
  }
}

.home-static-banner {
  display: none;

  @include sm {
    display: block;
    position: absolute;
    width: 100vw;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 0.65;
  }
}

.home-top-content {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  color: white;
  margin: 0 auto;
  width: 1024px;

  @include sm {
    width: 376px;
  }
}

.home-main-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  color: white;
  margin: 0 auto;
  width: 1024px;

  @include sm {
    width: 366px;
  }
}

.top-half-home-button-2 {
  border-radius: 4px;
  width: 7em;
  height: 2em;
}

.home-button-2 {
  border-radius: 4px;
  width: 7em;
  height: 2em;
}

.home-menu-container {
  display: flex;
  flex-direction: row;
  padding: 1.5em 0;
  justify-content: space-between;

  @include sm {
    flex-direction: column;
    align-items: center;
    padding: 2.5em 0;
  }
}

.home-menu-logo {
  width: 15em;

  @include sm {
    width: 90%;
    margin-bottom: 1em;
  }
}

.home-buttons-wrapper {
  display: flex;
  flex-direction: row;
}

.home-menu-buttons {
  margin-left: 1em;
  height: auto;
  width: auto;
  border-radius: 7.5px;
  overflow: hidden;
  font-size: 17px;
  @include sm {
    font-size: 15px;
  }
}

.learn-button {
  width: 145px;
  height: 46px;
  background-color: #606060;
  color: #F1F1F1;
  border-radius: 7.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include sm {
    width: 125px;
    height: 46px;
  }
}

.manage-button {
  width: 235px;
  height: 46px;
  color: #F1F1F1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-image: linear-gradient(to right, #97DBF8, #A8D25B) 1;
  border-width: 4px;
  border-style: solid;
  border-radius: 7.5px;
  @include sm {
    width: 205px;
    height: 46px;
  }
}

.home-banner-container {
  text-align: center;
}

.home-banner-title {
  padding-top: 3em;
  font-size: 2em;

  @include sm {
    font-size: 1em;
  }
}

.home-banner-description {
  max-width: 720px;
  margin: 0 auto 2em;
  font-size: 1.25em;

  @include sm {
    font-size: 1em;
  }
}

.home-preview-container {
  text-align: center;
}

.home-preview-image {
  perspective: 1000px;
  padding-top: 3em;
}

.home-preview-image img {
  width: 95%;
  border-radius: 0.5em;
  border: 1px solid gray;
}

.home-info-container {
  margin-top: 3em;
  padding-top: 3em;
}

.home-info-text {
  opacity: 0.2;
  font-size: 2.5em;
  font-weight: bold;

  @include sm {
    font-size: 1.5em;
  }
}

.home-product-container {
  text-align: center;
  padding-top: 5em;
  overflow: hidden
}

.home-carousel-dots {
  display: none;

  @include sm {
    display: block;
  }
}

.home-carousel-product {
  width: 1024px;
  height: 60vh;
  display: flex;
  flex-direction: row;
  background-color: rgb(46, 46, 46);
  border-radius: 18px;
  margin-right: 1em;
  position: relative;

  @include sm {
    flex-direction: column;
    height: 135vh;
    width: 366px;
    margin: 0px
  }
}

.carousel-product-logo {
  width: 20em;

  @include sm {
    width: 15em;
  }
}

.carousel-product-text {
  text-align: left;
  font-size: 0.85em;

  @include sm {
    font-size: 0.75em;
  }
}

.ecoDocs-border {
  background: linear-gradient(to bottom, #97E1F8, #A8D25B);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.carousel-product-preview {
  width: 10em;
  margin: 20px;
  aspect-ratio: 9/19.5;

  @include sm {
    margin: 0 20px;
    width: 6em;
  }
}

.home-pricing-container {
  text-align: center;
  padding-top: 5em;
}

.pricing-container-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @include sm {
    flex-direction: column;
    align-items: center;
  }
}

.pricing-container-element {
  background-color: rgb(31, 31, 31);
  width: 15em;
  height: 28em;
  margin-right: 1em;
  border-radius: 8px;
  padding: 1.5em;
  border: 1px solid gray;

  @include sm {
    margin-right: 0;
    margin-bottom: 1em;
  }
}

.tier-container {
  display: flex;
  width: 15em;
  flex-direction: column;
  border-radius: 1.1em;
  border-width: 0.31em;
  padding-left: 1.5em;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  border-style: solid;
  padding-right: 1.5em;
  background-color: #1e1e1e;
  margin-right: 2em;

  @include sm {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 36px;
    padding-bottom: 2.5em;
    border-radius: 16px;
    border-width: 4px;
    margin-bottom: 2.5em;
  }
}

.tier-button {
  border-radius: 6.5px;
  height: 2.75em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;

  @include sm {
    margin-top: 0.5em;
  }
}

.tier-button-text {
  color: #f1f1f1;
  font-size: 1em;
  font-weight: bold;
  font-family: 'Poppins';

  @include sm {
    font-size: 0.85em;
  }
}

.tier-text {
  font-size: 1.75em;
  font-weight: bold;
  font-family: 'Poppins';
  text-align: left;

  @include sm {
    font-size: 1.5em;
  }

}

.tier-italic {
  font-size: 0.9em;
  font-style: italic;
  font-family: 'Poppins';

  @include sm {
    font-size: 0.8em;
  }
}

.divider {
  border-top: 1px solid gray;
  margin-top: 5em;

  @include sm {
    border-top: 0.1em solid gray;
    margin-top: 1em;
  }
}

.tier-price {
  font-size: 3em;
  font-weight: bold;
  font-family: 'Poppins';

  @include sm {
    font-size: 2.5em;
  }
}


.pricing-container-element > * {
  padding-bottom: 1em;
}

.pricing-element-feature {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1em;
}

.pricing-element-feature > * {
  padding-right: 0.5em;
}

.home-footer-container {
  display: flex;
  flex-direction: column;
  background: radial-gradient(
    32.81% 53.93% at 50% 0%,
    rgba(133,102,255,.04) 0%,
    rgba(133,102,255,0) 100%,
    rgba(133,102,255,0) 100%
  );
}

.home-footer-top {
  display: flex;
  flex-direction: row;
  padding: 5em 0;

  @include sm {
    flex-direction: column;
  }
}

.footer-top-brand {
  max-width: 15em;
  margin-right: 10em;
}

.footer-top-brand * {
  margin-bottom: 2em;
}

.footer-top-nav {
  display: flex;
  flex-direction: column;
}

.footer-top-nav * {
  padding-bottom: 1em;
}

.home-footer-bottom {
  display: flex;
  flex-direction: row;
  padding: 5em 0;
  justify-content: space-between;

  @include sm {
    flex-direction: column;
    padding: 1em 0;
  }
}

.footer-bottom-copyright {
  @include sm {
    font-size: 0.8em;
    margin-bottom: 1em;
  }
}

.footer-bottom-socials * {
  padding: 0 0.5em;

  @include sm {
    padding: 0;
    padding-right: 1em;
  }
}

.top-half-footer-login-button {
  border-radius: 4px;
  width: 7em;
  height: 2em;
}

.footer-login-button {
  border-radius: 4px;
  width: 7em;
  height: 2em;
}



.login-main-container {
  -moz-box-sizing: border-box; 
  -webkit-box-sizing: border-box; 
   box-sizing: border-box; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  @include x-sm {
    justify-content: center;
  }
}

.login-back {
  height: 19px;
  width: 19px;
  position: fixed;
  top: 40px;
  left: 36px;
  content:url("/assets/EcoBack.svg");
  @include sm {
    content:url("/assets/EcoBackGray.svg");
  }
  @include x-sm {
    height: 15px;
    width: 15px;
    top: 69px;
    left: 16px;
  }
}

.login-logo-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #1E1E1E;
  flex: 1;
  height: 100%;
  @include sm {
    display: none;
  }
}

.login-form-area {
  display: flex;
  justify-content: center;
  background-color: #EAF2FF;
  flex: 1;
  height: 100vh;
  @include x-sm {
    width: 100vw;
    padding: 0 16px;
  }
}

.login-form-wrapper {
  width: 472px;
  @include x-sm {
    width: 100%;
  }
}

.login-logo-buffer {
  width: 100px
}

.login-eco-logo {
  border: 1px solid aqua;
  flex: 1;
}

.login-form-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 322px;
  height: 47px;
  margin: 195px auto;
  @include x-sm {
    margin: 64px auto;
    width: 203px;
    height: 29px;
  }
}

.login-password-input {
  margin-top: 116px;
  @include x-sm {
    margin-top: 143px
  }
}

.login-confirm-password-input {
  margin-top: 48px;
  @include x-sm {
    margin-top: 40px
  }
}

.login-btn-wrapper {
  display: flex;
  justify-content: center;
}

.login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #97DBF8;
  width: 305px;
  height: 52px;
  border-radius: 5000px;
  color: #FFFFFF;
  margin-top: 64px;
  font-size: 1em;
  font-weight: 600;
  font-family: 'Poppins';
  @include x-sm {
    margin-top: 68px;
    width: 100% !important;
    height: 58px;
    font-size: 16px;
  }
}

.login-footer-div {
  display: flex;
  justify-content: center;
  margin-top: 90px;
  @include x-sm {
    position: fixed;
    bottom: 15px;
    left: 0;
    width: 100%;
    text-align: center;
  }
}


.login-input {
  width: 460px;
  height: 52px;
  border-radius: 8px;
  border: 1.75px solid #d5e6f0;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 0.9em;
  color: #1e1e1e;
  background-color: #FFFFFF;
  @include x-sm {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 51px;
    width: 100%;
    font-size: 14px;
  }
}

.login-link {
  color: #76b1cc;
  font-size: 0.9em;
  font-family: 'Poppins';
  font-weight: 600;
  @include x-sm {
    font-size: 14px;
  }
}

.login-black-text {
  font-size: 0.9em;
  font-family: 'Poppins';
  font-weight: 400;
  color: #3f3f3f;
  @include x-sm {
    font-size: 15px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-moz-autofill,
input:-moz-autofill-preview-text {
    background-color: white !important;
    color: black !important;
}

input:focus {
  outline: none !important;
  border: none;
  box-shadow: 0 0 0 2px #97DBF8;
}
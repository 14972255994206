// Small devices
@mixin sm {
  @media (max-width: 1069px) {
    @content;
  }
}

// Mobile devices
@mixin x-sm {
  @media (max-width: 433px) {
    @content;
  }
}